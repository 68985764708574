.ant-dropdown {
}

.popover {
  width: max-content;
  max-width: 250px;
  font-size: 0.875rem;
  color: $TextColor;
  text-align: center;

  .ant-popover-inner {
    border-radius: $BorderRadius;
  }
}
