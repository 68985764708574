.word-form {
  .ant-upload {
    width: 175px;
    height: 150px;
  }

  section.word-image {
    @include flex();
    @include flex-wrap(wrap-reverse);
    @include justify-content(space-between);

    .words-tags {
      flex: 1;
      max-width: 600px;
      min-width: 300px;
      margin-right: 10px;
    }
  }
}
