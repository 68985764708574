.ant-modal-root {
  .ant-modal {
    .ant-modal-title {
      font-size: 1.35rem;
      font-weight: 800;
    }

    .ant-modal-content {
      border-radius: $BorderRadius;
    }
  }

  .modal-xlarge .ant-modal {
    width: 65% !important;

    @media (max-width: 1000px) {
      width: 90% !important;
    }
  }

  .modal-large .ant-modal {
    width: 55% !important;

    @media (max-width: 1000px) {
      width: 85% !important;
    }
  }

  .modal-medium .ant-modal {
    width: 46% !important;

    @media (max-width: 1100px) {
      width: 55% !important;
    }
    @media (max-width: 900px) {
      width: 75% !important;
    }
  }

  .modal-xlarge,
  .modal-large,
  .modal-medium,
  .modal-small {
    @media (max-width: 600px) {
      .ant-modal {
        width: 95% !important;
      }
    }
  }

  .page-root.page-loader {
    min-height: 100%;
  }
}
