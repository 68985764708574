.table-action-buttons {
  a {
    color: unset;
    font-weight: 600;
  }

  .table-more-button {
    margin-left: 5px;
    cursor: pointer;
  }
}

.ant-table {
  .ant-table-body {
    max-height: calc(100vh - 328px) !important;
  }
}

.ant-pagination {
  margin-bottom: 0 !important;
}

.table-filters {
  @include valign-wrapper();
  @include flex-wrap(wrap);
  @include justify-content(flex-end);

  > div {
    min-width: 200px;
  }

  > * {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
