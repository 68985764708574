@import "imports";
@import "components/index";
@import "modules/index";

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500&display=swap");

:root {
}

body {
  margin: 0;
  color: $TextColor;
  font-family: $FontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  width: 100%;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 48px;
}

.page-loader {
  @include flex();
  justify-content: center;
  align-items: center;
}
