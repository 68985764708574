.centered {
  @include centered();
}

.valigned {
  @include valign-wrapper();
}

.space-between {
  @include flex();
  @include justify-content(space-between);
}

.clickable {
  @include clickable();
}

.cursor-pointer {
  cursor: pointer;
}

.danger {
  color: $Red !important;
}
