.ant-input-number {
  width: 100%;
}

.ant-form-item {
  max-width: 700px;

  .ant-form-item-label label {
    font-weight: 600;
  }

  .react-audio-player {
    width: 100%;
    height: 36px;
  }
}

.form-list-items-wrapper {
  @include scrollbar-x();
  overflow-x: auto;
}

.form-list-items, .form-list-items-horizontal {
  width: 100%;
  max-width: 100%;
}

.form-list-items {
  margin-bottom: 5px;

  .ant-form-item-control-input-content {
    @include flex();
    @include align-items(flex-start);

    .dynamic-edit-button {
      align-self: center;
      -webkit-align-self: center;
      margin-right: 10px;
      font-size: 1rem;
    }

    .dynamic-delete-button {
      align-self: center;
      -webkit-align-self: center;
      font-size: 0.875rem;
    }

    .ant-row {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .ant-form-item {
    min-width: 200px;
    flex: 1;

    .ant-input, .ant-select {
      min-width: 100%;
      width: 100%;

      &[type=file] {
        font-size: 12px;
      }
    }
  }
}

.multiselect {
  max-height: 310px;
  min-height: 50px; // loader
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar-y();
  border: 1px solid $BorderColor;
  padding: 10px;

  &.ant-checkbox-group {
    width: 100%;

    .ant-checkbox-group-item {
      width: 100%;
    }
  }

  &.ant-empty {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 0.8rem;

    svg {
      width: 48px;
    }
  }
}
