$FontFamily: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$FontFamilyHeading: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

// Colors
$AccentColor: #1890ff;
$BackgroundColor: #F0F2F5;
$TextColor: rgba(0, 0, 0, 0.85);
$TextColorLight: #808080;
$TextColorDark: rgba(0, 0, 0, 0.75);

$Red: rgba(red, 0.8);

$BorderColor: rgba(0, 0, 0, 0.12);

// Elements
$HeaderHeight: 80px;
$SidebarWidth: 240px;
$ScrollbarColor: #68717a;

$BodyPadding: 36px;
$BorderRadius: 6px;

$PageHeight: calc(100vh - #{$HeaderHeight});
$PageWidth: calc(100vw - #{$SidebarWidth});

// Media Query Ranges
$small-screen-up: 657px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 656px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
