$BorderRadiusButton: 4px;

.ant-btn {
  border-radius: $BorderRadiusButton;
  font-weight: 600;

  &.ant-btn-text {
    color: $AccentColor;
    padding-left: 0;
    padding-right: 0;
  }

  &.wide {
    padding-left: 36px;
    padding-right: 36px;
  }
}

.text-button {
  display: inline-block;
  cursor: pointer;
  background-color: #f1f1f1;
  padding: 8px 6px;
  border: 1px solid #d1d1d1;
  margin: 4px 2px;
}
.view {
  background-color: #1890ff;
  padding: 8px 10px;
}
.delete {
  background-color: #f1f1f1;
}
