.ant-page-header {
  padding: 0 0 24px;

  .ant-page-header-heading-title {
    font-size: 2rem;
    font-weight: 900;
    color: $TextColor;
  }
}

.ant-form {
  > section {
    margin-bottom: 42px;
    border-bottom: 1px solid $BorderColor;
  }

  .form-section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: $TextColorDark;
  }

  .form-buttons {
    @include valign-wrapper();
    @include justify-content(space-between);
    margin-top: 16px;
  }
}
