@mixin flex() {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
}

@mixin flex-wrap($wrap) {
  flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
}

@mixin flexed($value) {
  flex: $value;
  -ms-flex: $value;
  -webkit-flex: $value;
}

@mixin flex-direction($direction) {
  flex-direction: $direction;
  -ms-flex-direction: $direction;
  -webkit-flex-direction: $direction;
}

@mixin justify-content($justify) {
  justify-content: $justify;
  -webkit-justify-content: $justify;
}

@mixin align-items($align) {
  align-items: $align;
  -webkit-align-items: $align;
}

@mixin valign-wrapper() {
  @include flex();
  @include align-items(center);
}

@mixin centered() {
  @include flex();
  @include align-items(center);
  @include justify-content(center);
}

@mixin clickable() {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

@mixin scrollbar-y($width: 6px) {
  &::-webkit-scrollbar {
    width: $width;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $ScrollbarColor;
    height: 56px;
    border-radius: 24px;
  }
}

@mixin scrollbar-x($x-height: 6px) {
  &::-webkit-scrollbar {
    width: 8px;
    height: $x-height;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $ScrollbarColor;
    width: 56px;
  }
}

@mixin disable-text-selection() {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@mixin disable-image-drag() {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
