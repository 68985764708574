h1, h2, h3, h4, h5, h6 {
  font-family: $FontFamilyHeading;
  color: $TextColor;
  margin-bottom: 0;
  line-height: normal;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

p {
  margin-bottom: 0;
}

.font-normal {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semibold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}
