.login-form {
  &.card {
    width: 60%;
    max-width: 600px;

    @media #{$small-and-down} {
      width: 90%;
    }
  }
}
