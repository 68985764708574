.ant-layout {
  position: relative;
  background-color: $BackgroundColor;

  &.app-layout {
    min-height: 100vh;
  }
}

.ant-layout-sider {
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 999;

  .sidebar-header {
    min-height: $HeaderHeight;
    @include centered();
    font-weight: 900;
    color: white;
    font-size: 1.5rem;
  }

  .ant-menu {
    .ant-menu-item {
      transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
      @include disable-text-selection();

      a {
        transition: color 0.3s ease-in-out;
      }

      &.active {
        font-weight: 600;
        background-color: rgba(white, .12);

        a {
          color: rgba(white, 0.96);
        }
      }

      &:hover {
        background-color: rgba(white, .12);
      }
    }

    .divider {
      border-top: 1px solid rgba(white, 0.12);
    }
  }
}

.ant-layout-header {
  height: $HeaderHeight;
  min-height: $HeaderHeight;
  position: fixed;
  background-color: rgba(white, 0.97);
  padding: 4px $BodyPadding;
  @include valign-wrapper();
  @include justify-content(space-between);
  width: $PageWidth;
  margin-left: $SidebarWidth;
  z-index: 998;

  .profile-dropdown {
    width: max-content;
  }
}

.ant-layout-content {
  margin-top: $HeaderHeight;
  margin-left: $SidebarWidth;
}

.page-root {
  padding: $BodyPadding;
  height: 100%;

  &.full-page {
    min-height: 100vh;
  }

  &.page-loader {
    min-height: 100vh;
  }
}
